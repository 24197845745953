<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Báo cáo hàng tồn</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="openSearch()" class="md-raised md-primary btn-add">Tìm <span>k</span>iếm<md-tooltip>Tìm kiếm (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'e']" @click="exportExcel()" class="md-raised md-primary btn-add">
                            Export<md-tooltip>Xuất excel</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:50px;">#</th>
                                <th style="width:180px;">Mã vật tư</th>
                                <th style="width:20%;">Tên vật tư</th>
                                <th style="width:10%;">Trạng thái</th>
                                <th style="width:10%;">ĐVT</th>
                                <th style="width:10%;">Tồn đầu kỳ</th>
                                <th style="width:10%;">Tổng nhập</th>
                                <th style="width:10%;">Tổng xuất</th>
                                <th style="width:10%;">Tồn Kho</th>
                                <th style="width:10%;">Kho hàng</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td>{{item.productCode}}</td>
                                <td>{{item.productName}}</td>
                                <td class="center">{{item.status}}</td>
                                <td class="center">{{item.unitName}}</td>
                                <td class="right">{{item.openingStore}}</td>
                                <td class="right">{{item.inputStore}}</td>
                                <td class="right">{{item.outputStore}}</td>
                                <td class="center">{{item.closingStore}}</td>
                                <td>{{item.storeName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                 <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <searchForm ref="searchForm" @close="closeSearch" />
    </div>
</template>
<script>
    import reportStoreService from '../../../api/reportStoreService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import searchForm from './_SearchInventory.vue';
    import { APP_CONFIG } from '@/utils/constants'

    export default {
        components: {
            searchForm
        },
        metaInfo: {
            title: 'Báo cáo hàng tồn'
        },
        data() {
            return {
               loadding: false,
               search: { pageIndex: 1, pageSize: common.pageSize, fromDate: common.dateNow, toDate: common.dateNow, storeId: 0, productId: 0 },
               pager: { totalPages: 1 },
               data: [],
               rowPerPageOptions: [],
               id: 0
            }
        },
        created(){
            this.setLoading(false);
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
        },
        mounted() {
            this.openSearch();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/report-store/export-report-inventory?fromDate=" + 
                this.search.fromDate + "&toDate=" + this.search.toDate + "&storeId=" + this.search.storeId
                + "&productId=" + this.search.productId;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            openSearch(){
                this.$refs.searchForm.open();
            },

            closeSearch(search){
                this.search = search;
                this.getData();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.pageIndex = 1;
                this.search.pageSize = common.pageSize;
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/report-inventory?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                reportStoreService.getReportInventory(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
        },
        watch: {
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.code': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.name': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
